import * as Sentry from '@sentry/browser';
const { sentryDsn, env, version } = document.body.dataset;
if (sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        environment: env,
        release: version,
        integrations: [
            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: ['shop', 'admin'],
                behaviour: 'drop-error-if-contains-third-party-frames',
            }),
        ],
    });
}
