import { __decorate } from "tslib";
import $ from 'jquery';
import { throttle } from 'helpful-decorators';
import LazyImages from './lazy-images';
import { getCurrentDeviceScreenType } from '../utils/breakpoint';
import { DeviceScreenType } from '../types/breakpoint';
import { getCurrentBreakpoint } from '../utils/breakpoint';
import { Breakpoint } from '../types/breakpoint';
import { checkWebpSupport } from '../utils/webp';
export default class BackgroundImage {
    static $bgImageElement = $('.js__bg-image');
    static isWebpSupported;
    static currentLoadedImageDeviceScreenType = null;
    static async init() {
        if (!this.$bgImageElement.length) {
            return;
        }
        this.isWebpSupported = await checkWebpSupport();
        $(window).on('resize', () => {
            this.actionsOnResize();
        });
        this.initBgImagesLoading();
    }
    static initBgImagesLoading() {
        this.$bgImageElement.each((key, item) => {
            this.displayImage($(item));
        });
    }
    static actionsOnResize() {
        const currentDeviceScreenType = getCurrentDeviceScreenType();
        if (this.currentLoadedImageDeviceScreenType !== currentDeviceScreenType) {
            this.initBgImagesLoading();
            this.currentLoadedImageDeviceScreenType = currentDeviceScreenType;
        }
    }
    static displayImage($bgElement) {
        const imagePath = this.getImagePath($bgElement);
        const currentBreakpoint = getCurrentBreakpoint();
        if (imagePath) {
            this.addImageToBackground($bgElement, imagePath);
        }
        else if (currentBreakpoint < Breakpoint.lg && $bgElement.data('hide-mobile-image')) {
            this.removeBackgroundImage($bgElement);
        }
    }
    static getImagePath($bgElement) {
        const currentBreakpoint = getCurrentBreakpoint();
        const currentDeviceScreenType = getCurrentDeviceScreenType();
        const isMobileImageExist = $bgElement.data(`bg-image-mobile`);
        const webpSuffix = this.isWebpSupported ? '-webp' : '';
        if ($bgElement.data('hide-mobile-image') && currentBreakpoint < Breakpoint.lg) {
            return '';
        }
        return !isMobileImageExist || currentDeviceScreenType === DeviceScreenType.Desktop
            ? $bgElement.data(`bg-image${webpSuffix}`)
            : $bgElement.data(`bg-image-mobile${webpSuffix}`);
    }
    static addImageToBackground($bgElement, imagePath) {
        const webpSuffix = this.isWebpSupported ? '-webp' : '';
        if (!$bgElement.attr(`data-bg${webpSuffix}`)?.includes(imagePath)) {
            $bgElement.attr('data-was-processed', 'false');
            $bgElement.attr('data-bg', `url(${imagePath})`);
            LazyImages.updateBackground();
        }
    }
    static removeBackgroundImage($bgElement) {
        $bgElement.css('background-image', '');
    }
}
__decorate([
    throttle(300)
], BackgroundImage, "actionsOnResize", null);
