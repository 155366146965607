import $ from 'jquery';
import 'select2';
export default class Select2Forms {
    static isSelectOpenPrevented = false;
    static init($container = $('body')) {
        $container
            .find('select')
            .filter((index, element) => !$(element).data('select2'))
            .each((index, element) => {
            const $element = $(element);
            $element.select2({
                theme: 'nbw',
                ...(!$element.data('search') ? { minimumResultsForSearch: Infinity } : {}),
                dropdownParent: $element.data('parent-selector'),
                language: {
                    noResults: () => $element.data('not-found-message') || 'No results found',
                },
            });
            const $clearButton = $element.parent().find('.js__clear-select');
            this.setClearButtonAttributes($element, $clearButton);
            $clearButton
                .on('click', () => {
                $element.val('').trigger('change');
            })
                .on('keydown', event => {
                if (event.key === 'Enter' || event.key === ' ') {
                    $element.val('').trigger('change');
                    // @ts-ignore
                    $element.select2('focus');
                }
            });
            $element.on('change', event => {
                this.setSelectedClass($(event.target));
                if ($element.closest('.is-invalid').length) {
                    $element.valid();
                }
                if ($clearButton.length) {
                    this.setClearButtonAttributes($element, $clearButton);
                }
            });
            $element.parent().find('span[role="textbox"]').removeAttr('role');
            // make select open after inputs validation
            $element.on('select2:opening', event => {
                if (!this.isSelectOpenPrevented) {
                    event.preventDefault();
                    this.isSelectOpenPrevented = true;
                    requestAnimationFrame(() => {
                        $(event.currentTarget).select2('open');
                        this.isSelectOpenPrevented = false;
                    });
                }
            });
            const $filterWrapper = $element.closest($element.data('filter-wrapper'));
            const $filterElement = $filterWrapper.find($element.data('filter-element'));
            const $filterElementInput = $filterElement.filter('input:checked');
            $filterElement.filter('input').on('change', (event) => {
                this.filterSelectOptions($(event.currentTarget), $element);
            });
            if ($filterElementInput.length) {
                this.filterSelectOptions($filterElementInput, $element);
            }
            this.setSelectedClass($element);
        });
    }
    static setClearButtonAttributes($element, $clearButton) {
        if ($element.val()) {
            $clearButton.attr('tabindex', '0');
        }
        else {
            $clearButton.removeAttr('tabindex');
        }
    }
    static filterSelectOptions($filterElement, $select) {
        const filterValue = $filterElement.val();
        const filterParameter = String($select.data('filter-parameter') || '');
        $select
            .find('option')
            .prop('disabled', false)
            .filter((index, element) => {
            return $(element).data(filterParameter) !== filterValue;
        })
            .prop('disabled', true);
        if (!$select.prop('disabled')) {
            $select.val('').trigger('change');
        }
    }
    static setSelectedClass($element) {
        $element.data('select2').$container.toggleClass('select2-container--selected', $element.val() !== '');
    }
}
