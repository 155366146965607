import $ from 'jquery';
import { scrollToElement } from '../../utils/scroll-to-element';
import StickyBar from '../../components/sticky-bar';
export default class AnchorHashScrollTo {
    static $anchors = $('a[href^="#"]:not(a[href="#"]):not([data-toggle="collapse"]),.js__scroll-to-element');
    static init() {
        const stickyBarOffset = StickyBar.getStickyBarHeight() || 0;
        this.$anchors.on('click', event => {
            if (this.$anchors.attr('role') === 'tab') {
                return;
            }
            event.preventDefault();
            const $element = $(event.currentTarget);
            const href = $element.attr('href') || '';
            const $target = href.includes('/') ? $(`#${href.split('#')[1]}`) : $(href);
            if (!$target.length) {
                return;
            }
            if ($target.length) {
                scrollToElement($target, stickyBarOffset);
            }
        });
    }
}
